code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  font-size: 14px;
}

body {
  font-family: var(--font-family);
  font-weight: normal;
  background: var(--surface-ground);
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.p-selectbutton {
  font-weight: normal;
}

.login-button-mini {
  font-size: 12px;
  padding: 2px;
}

.p-card-body-custom {
  padding: 1px;
}